.btn a {
    color: #fff;
}

.contact.section {
    padding-bottom: 6.25rem;
}

.contact__container {
    grid-template-columns: 4fr 8fr;
    column-gap: 1.875rem;
}

.contact__title {
    font-size: var(--h3-font-size);
    margin-bottom: .5rem;
}

.contact__form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
}

.contact__form-div {
    position: relative;
    margin-bottom: 1.875rem;
    height: 3.75rem;
}
.contact__form-div label{
    position: absolute;
    z-index: 1;
    left: 30px;
    top: 15px;
    color: var(--title-color);
    transition:cubic-bezier(0.6, -0.28, 0.735, 0.045) .3s;
    cursor: text;
}

.contact__form-div:focus, .contact__form-input:focus {
    box-shadow:  0px 5px 20px 0px hsla(43, 100%, 68%, 0.322);
}

.contact__form-div:focus, 
.contact__form-input:focus ~ label{
    transform: translateY(-35px);
    font-size: var(--smaller-font-size);
}

.contact__form-div:focus, 
.contact__form-input:valid ~ label{
    transform: translateY(-35px);
    font-size: var(--smaller-font-size);
}

.contact__form-div #email:invalid , 
.contact__form-input:valid ~ text{
    color: var(--body-color);
}

.contact__form-div #email:focus , 
.contact__form-input:invalid ~ text{
    color: var( --text-color);
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: var(--shadow);
    background: var(--container-color);
    color: var(--text-color);
    border: none;
    outline: none;
    border-radius: 1.875rem;
    padding: .625rem 1.875rem;
    z-index: 1;
}

.contact__form-area {
    height: 10.25rem;
}

.contact__form-area textarea{
    resize: none;
}

@media screen and (max-width: 1024px) {
    .contact__container {
        grid-template-columns: 300px 360px;
        justify-content: center;
    }

    .contact__form-group {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 768px) {
    .contact__container {
        grid-template-columns: 310px;
        row-gap: 1.875rem;
    }

    .contact__info {
        text-align: center;
    }
}

@media screen and (max-width: 350px) {
    .contact__container {
        grid-template-columns: 1fr;
    }
}