.about__container {
    grid-template-columns: 3fr 9fr;
    column-gap: 1.875rem;
}

.about__info {
   text-align: center;
}

.about__data {
    padding: 1.875rem;
    background: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
    align-items: flex-start;
    position: relative;
}

.about__data::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 15px solid var(--container-color);
    position: absolute;
    left: -0.93rem;
    top: 20%;
}

.about__description {
    margin-bottom: 1rem;
    text-align: center;
}

.about__skills {
    row-gap: 1.25rem;
}

.skills__titles {
    display: flex;
    justify-content: space-between;
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills__number {
    line-height: 1.2;
}

.skills__bar,
.skills__percentage {
    height: 7px;
    border-radius: 0.25rem;
}

.skills__bar {
    background: #f1f1f1;
}

.skills__percentage {
    display: block;
    background: var(--first-color);
}

.development {
    width: 90%;
    background: rgb(255, 209, 92);
}

.ui__design {
    width: 75%;
    background: rgb(255, 92, 92);
}

.soft__skills {
    width: 95%;
    background: rgb(92, 81, 241);
}

.language__skills {
    width: 85%;
    background: rgb(255, 92, 92);
}

.about__boxes {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.875rem;
    margin-top: 4.35rem;
}

.about__title:hover::after {
    content: 'Ficticional numbers';
    font-size: .4rem;
    margin-left: 2px;
    position: absolute;
    top: 100%;
    left: 25%;
}

.about__box {
    display: flex;
    column-gap: 1.5rem;
    position: relative;
}

.about__icon {
    font-size: var(--h1-font-size);
    color: #dedeea;
}

.about__title {
  font-size: 1.875rem;  
}

.about__img {
    animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 30% 20% 70%/60% 20% 70% 30%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 30% 20% 70%/60% 20% 70% 30%;
    }
}

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 720px;
        justify-content: center;
        row-gap: 1.875rem;
    }

    .about__data::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        left: 49%;
        top: -20px;
    }

    .about__img {
        justify-self: center;
        width: 108px;
    }

    .about__box {
        flex-direction: column;
        row-gap: .5rem;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .about__container {
        grid-template-columns: 350px;
    }

    .about__data {
        grid-template-columns: 1fr;
        row-gap: 1.875rem;
    }

    .about__boxes {
        grid-template-columns: repeat(2, 150px);
        row-gap: 1.5rem;
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {
    .about__container {
        grid-template-columns: 320px;
    }
}

@media screen and (max-width: 350px) {
    .about__container {
        grid-template-columns: 1fr;
    }

    .about__boxes {
        grid-template-columns: 1fr;
    }
}